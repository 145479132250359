/* eslint-disable react/prop-types */


import {
  CCard,
  CCol,
  CRow,
  CCardBody,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CListGroup,
  CListGroupItem,
  CCloseButton,
  CFormTextarea
} from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import ReactTable from 'react-table-6'
import { AppHeader, AppSidebar } from 'src/components'
import FiterDropDown from 'src/components/contact/FilterDropDown'

const Contact = () => {
  const heading = [
    {
      Header: 'Contacts',
      columns: [
        { accessor: 'stationId', Header: 'Station Id' },
        { accessor: 'name', Header: 'Name' ,width: 200},
        {
          accessor: 'mobileNo', Header: 'Mobile No',
          width: 200,
          Cell:(props)=>{
          const contact = props?.original?.mobileNo;
          if(Array.isArray(contact)){
            return (
              <>
              <CListGroup className="list-group" >
                {
                  contact?.map((item,index)=>(
                    <>
                    <CListGroupItem className="list-group-item" key={index}><a href={`tel:${item}`}> {item}</a> </CListGroupItem>
                    </>
                  ))
                }
              </CListGroup>
              </>
            )

          } else if(contact){
            return( <>
              <ul style={{"listStyle":"none"}}>
                <li ><a href={`tel:${contact}`}>{contact}</a></li>
              </ul>
            </>)

          }
          else{
            return (
              <>
<div> Contact is not available</div>
              </>
            )
          }
        }
        },
        { accessor: 'contactType', Header: 'Contact Type' ,  width: 200, },
        { accessor: 'designation', Header: 'Designation' ,width:200},
        { accessor: 'remarks', Header: 'Remarks' },
        {
          accessor: 'postalAddress', Header: 'Postal Address' ,
          width:300
        },
        { accessor: 'divisionId', Header: 'Division ID' },
        { accessor: 'zoneId', Header: 'Zone Id' },
        { accessor: 'email', Header: 'Email' ,width: 200},
       
       

      ],
    },
  ];
  

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [zonelist , setZonelist] = useState([])
  const [divisonlist , setDivisionList] = useState([])
  const [stationlist , setStationlist] = useState([])
  const [contactNumber , setContactNumber ] = useState("")
  const [fiterby, setFilterBy] = useState("");
  const [ empty , setEmpty] = useState(false); 
  const [data, setData] = useState({
    stationId: '_ALL',
    divisionId: '_ALL',
    zoneId: '_ALL',
    contactType: 'Select the Contact Type',
    name: '',
    designation: '',
    email: '',
    remarks: '',
    mobileNo: '',
    postalAddress: '',
  });
 const[show , setShow ] = useState(true)
  const [mobileNoInput, setMobileNoInput] = useState([]);
  const [contentType , setContentType] = useState("Select the Contact Type");
  const [selecte , setSelect] = useState([])
  const onChangeForm = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));


  }

  const zonechange = (event) => {
    let zoneid = event.target.value;
    
    if (zoneid === "_ALL") {
      setData((prevData) => ({
        ...prevData,
        zoneId: zoneid,
        divisionId: '_ALL',
        stationId: '_ALL',
      }));
  
      getAlldivision();
      getAllStation();
    } else {
  
      setData((prevData) => ({
        ...prevData,
        zoneId: zoneid,
      }));
  
      getDivisionByZone(zoneid);
    }
  };
  
const divisionChange = (event)=>{
  const selectedDivision = event.target.value;
  setData({ ...data, divisionId: selectedDivision });

  if (selectedDivision === '_ALL') {
  
    setData(...data ,{  stationId: '_ALL' });
  } else {
     getstationByzone(event.target.value)
  }
}

  const getAllZone = async()=>{
    axios.get('https://api.irhabd.com/v1/area/zone',{}).then((res)=>{
      let subtype = res.data.length >0 && res.data.map((item, index)=>{
        return ( <option key={index} value={item.code}>
          { item.name}
        </option> )
      })
    
      setZonelist(subtype)

    }).catch((error)=>{
       console.error("Error is "+ error)
    })
  }

  const getAlldivision = ()=>{
     axios.get("https://api.irhabd.com/v1/area/divisions",{}).then((res)=>{
      let subtype = res.data.length>0 && res.data.map((item,i)=>{
        return (
          <option key={i} value={item.id}>
            {item.name}
          </option>
        )
      })
      setDivisionList(subtype)

     }).catch((error) => {
        console.error("Error is " + error)
     })
  }

  
  const getAllStation = ()=>{
    axios.get("https://api.irhabd.com/v1/area/stations",{}).then((res)=>{
     let subtype = res.data.length>0 && res.data.map((item,i)=>{
       return (
         <option key={i} value={item.id}>
           {item.station_name}
         </option>
       )
     })
     setStationlist(subtype)

    }).catch((error) => {
       console.error("Error is " + error)
    })
 }
  const getDivisionByZone = async(zoneid)=>{
    axios.get(`https://api.irhabd.com/v1/area/divisions/${zoneid}`).then((res)=>{
      if(  res.data && res.data.length>0){
  let firstDivisionId = res.data[0].id; 
  
  const divison =res.data.map((item, index)=>{
    getstationByzone(item.id)
    return( <option key={index} value={item.id}>{ item.name} </option>)
  })
  setDivisionList(divison)
  setData((pre)=>({...pre , divisionId:firstDivisionId}))
      }
    }).catch((error)=>{
       console.error("error is " + error);
    })
  }
  const  getstationByzone =(divisionId)=>{
     axios.get(`https://api.irhabd.com/v1/area/stations/${divisionId}`).then((res)=>{
     
     if(res.data && res.data.length>0){
   let firstid = res.data[0].id;
   const station = res.data.map((item,index)=>{
      return ( <option key={index} value ={item.id}> {item.station_name} </option>)
   })
   setStationlist(station)
   setData((pre)=>({...pre , stationId:firstid}))
     } 
     }).catch((error)=>{
      console.error("error is " + error);
   })

  }
 
  const stationchnage = (event)=>{
    let stationvalue = event.target.value 
    setData((pre)=>({...pre , stationId:stationvalue}))
     
  }
  useEffect(()=>{
    getAllZone()
    getAlldivision()
    getAllStation()
  },[])
 
  const handleAdd = (e)=>{
    e.stopPropagation();
    getAlldivision()
    getAllStation()
     setVisible(!visible)
     
  }

  
const onSubmit = (e) => {
    e.preventDefault(); // Prevent the page from reloading on form submission

    axios.post('https://api.irhabd.com/v1/contacts', data)
      .then((response) => {
        console.log(response, "response of post");

        // Reset the form data after successful submission
        setData({          
          name: '',
          email: '',
          remarks: '',
          mobileNo: '',
          postalAddress:''
        });
        setContactNumber("");
        setMobileNoInput([]);
        // Fetch updated data to reflect the new contact list after submission
        fetchData();
      })
      .catch((error) => {
        console.error('There was an error posting the data!', error);
        alert("There was an error posting the data!");
      });
  }

 



  const AddNumber = () => {
    const contatArray = Array.from(String(contactNumber), Number)
    if (contactNumber !== "" && contatArray.length === 10) {
      const updatedMobileNo = [...mobileNoInput, contactNumber];
      setMobileNoInput(updatedMobileNo);

      setContactNumber('');
        setData((pre) => ({ ...pre, mobileNo: JSON.stringify(updatedMobileNo) }));
    } else {
      alert("Enter a valid 10-digit number");
    }
  };
    const handleRemove = (index)=>{
    const updatedMobilelist = [...mobileNoInput];
    updatedMobilelist.splice(index,1);
    setMobileNoInput( updatedMobilelist)
    setData((pre)=>({...pre , mobileNo:JSON.stringify(updatedMobilelist)}))
  }
  const handleMobileChnage =(event)=>{
    setContactNumber(Number(event.target.value))
 }

 const handleContactTypeChange = (event) => {
  let contentType = event.target.value; 

  if (contentType === "Select the Contact Type") {
    setShow(true);
  } else {
    setShow(false);
  }

  setContentType(contentType);
  setData((prev) => ({ ...prev, contactType: contentType }));
};
 
const onSelectChange = (data) => {
  if (data.length === 0) {
    setSelect(tableData);
    setEmpty(false);
  } else {

    const filteredData = data.flatMap((item) =>
      tableData.filter((data) =>
        fiterby === "station"
          ? data.stationId === item.value
          : fiterby === "division"
          ? data.divisionId === item.value
          : data.zoneId === item.value
      )
    );
    setSelect(filteredData);
    if (filteredData.length === 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }
};
console.log(data ,"data")
const fetchData = async () => {
  setLoading(true);
  try {
    const response = await axios.get("https://api.irhabd.com/v1/contacts");
    setTableData(response.data); 
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
} 
useEffect(()=>{
 fetchData();
},[])
  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column  bg-light">
          <AppHeader />
          <div className="body flex-grow-1 px-3">
            <CRow>
              <CCol xs={12}>
              <CCol className='d-flex justify-content-center gap-3 mb-4'>
  <CRow >
  
   <div>
   <FiterDropDown
  onFilterChange={(d) => {
    if(fiterby === ""){
 setFilterBy("station")

    }
    else{
      setFilterBy(d.filterBy)
    }
  }}
  onselectChange={onSelectChange}
/>
   </div>
  </CRow>
</CCol>
                <CCard className="mb-4">
                  <CCardBody>
                    <CRow>
                      <CCol md={12}>
                        <ReactTable
                          pageSize={10}
                          columns={heading}
                          data={selecte.length>0? selecte :empty ?  []: tableData}
                          loading={loading}
                          className="-striped -highlight"
                          
                        />
                         
                        <CRow className="justify-content-center mt-4">
                          <CCol className="text-center">
                            <CButton
                              color="primary"
                              className="px-4"
                              onClick={(e)=>handleAdd(e)}
                            >
                              Add
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </div>
        </div>

        <CModal
          backdrop="static"
          visible={visible}
          onClose={(e) => { 
            if(e){
              e.stopPropagation();
            }
            setVisible(false);  setData({
            stationId: '_ALL',
            divisionId: '_ALL',
            zoneId: '_ALL',
            contactType: '',
            name: '',
            designation: '',
            email: '',
            remarks: '',
            mobileNo: '',
          });
          setMobileNoInput([])
          setShow(true)
          fetchData()
        }
        
      }
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Add Contacts
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CForm>
            <CRow className="mb-2">
                <CFormLabel htmlFor="zoneId" className="col-sm-3 col-form-label">
                  Zone Id
                </CFormLabel>
                <CCol sm={8}>
                  <CFormSelect
                    id="zoneId"
                    name="zoneId"
                    placeholder="Enter Zone Id"
                    value={data.zoneId }
                    onChange={zonechange}
                  >
                  { zonelist}
                  </CFormSelect>
                </CCol>
              </CRow>
            <CRow className="mb-2">
                <CFormLabel htmlFor="divisionId" className="col-sm-3 col-form-label">
                  Division Id
                </CFormLabel>
                <CCol sm={8}>
                  <CFormSelect
                    id="division_id"
                    name="division"
                    placeholder="Enter Division Id"
                    value={data.divisionId }
                    onChange={divisionChange}
                  >  {divisonlist}</CFormSelect>
                </CCol>
              </CRow>
            
              <CRow className="mb-2">
                <CFormLabel htmlFor="stationId" className="col-sm-3 col-form-label">
                  Station Id
                </CFormLabel>
                <CCol sm={8}>
                  <CFormSelect
                 
                    id="stationId"
                    name="stationId"
                    placeholder="Enter Station Id"
                    value={data.stationId }
                    onChange={stationchnage}
                  > {stationlist}</CFormSelect>
                </CCol>
              </CRow>
          
              <CRow className="mb-2">
                <CFormLabel htmlFor="contactType" className="col-sm-3 col-form-label">
                  Contact Type
                </CFormLabel>
                <CCol sm={8}>
                  <CFormSelect
                    type="text"
                    id="contactType"
                    name="contactType"
                    value={contentType || ''}
                    onChange={(e)=>handleContactTypeChange(e)}  
                  > 
      <option value ="Select the Contact Type"> Select the Contact Type </option>
                     <option value ="Local" > Local</option>
                     <option  value="Railway" > Railway</option>
                    
                   </CFormSelect>
                </CCol>
              </CRow>
              <CRow className="mb-2">
                <CFormLabel htmlFor="designation" className="col-sm-3 col-form-label">
                  Designation
                </CFormLabel>
                <CCol sm={8}>
                <CFormSelect
  id="designation"
  name="designation"
  disabled={show}
  value={data.designation}
  onChange={(e)=>onChangeForm(e)}
>

{
  contentType === "Railway" ? (
    <>   <option > Select the Contact Type </option>
      <option value="SSE/C&W">SSE/C&W</option>
      <option value="JE/C&W">JE/C&W</option>
      <option value="SSE/C&W/IC">SSE/C&W/IC</option>
      <option value="SSE/PWAY">SSE/PWAY</option>
      <option value="SSE/Electrical">SSE/Electrical</option>
      <option value="TXR/Staff">TXR/Staff</option>
      <option value="TXR/IC">TXR/IC</option>
      <option value="Control">Control</option>
      <option value="Control/IC">Control/IC</option>
      <option value="Control/Staff">Control/Staff</option>
      <option value="SSE/Drawing">SSE/Drawing</option>
      <option value="SSE/Planning">SSE/Planning</option>
      <option value="SSE/Drawing">SSE/Drawing</option>
      <option value="JE/Planning">JE/Planning</option>
      <option value="Store/IC">Store/IC</option>
      <option value="Store/Staff">Store/Staff</option>
      <option value="Account/SO">Account/SO</option>
    </>
  ) : contentType === "Local" ? (
    <> 
      <option value ="Select the Contact Type"> Select the Contact Type </option>
      <option value="Electrician">Electrician</option>
      <option value="Auto">Auto</option>
      <option value="Fabricator">Fabricator</option>
      <option value="Gate Man">
         Gate Man
      </option>
      <option value="Shop Man">Shop Man</option>
      <option value="Restaurant">Restaurant</option>
      <option value="Hotel">Hotel</option>
    </>
  ) : (
    <option value="">Select the Contact Type</option>
  )
}

</CFormSelect>
                </CCol>
              </CRow>
              <CRow className="mb-2">
                <CFormLabel htmlFor="name" className="col-sm-3 col-form-label">
                  Name
                </CFormLabel>
                <CCol sm={8}>
                  <CFormInput
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    value={data.name || ''}
                    onChange={onChangeForm}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-2">
                <CFormLabel htmlFor="email" className="col-sm-3 col-form-label">
                  Email
                </CFormLabel>
                <CCol sm={8}>
                  <CFormInput
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    value={data.email || ''}
                    onChange={onChangeForm}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-2">
                <CFormLabel htmlFor="remarks" className="col-sm-3 col-form-label">
                  Remarks
                </CFormLabel>
                <CCol sm={8}>
                  <CFormInput
                    type="text"
                    id="remarks"
                    name="remarks"
                    placeholder="Enter Remarks"
                    value={data.remarks || ''}
                    onChange={onChangeForm}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-2">
                <CFormLabel htmlFor="remarks" className="col-sm-3 col-form-label">
                Postal Address
                </CFormLabel>
                <CCol sm={8}>
                  <CFormTextarea
                    type="text"
                    id="postalAddress"
                    name="postalAddress"
                    placeholder="Enter Remarks"
                    value={data.postalAddress }
                    rows={3}
                    onChange={onChangeForm}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-2">
                <CFormLabel htmlFor="mobileNo" className="col-sm-3 col-form-label">
                  Mobile No
                </CFormLabel>
                <CCol sm={8}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>+91</span>
                  <CFormInput
                maxLength={10}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    type="tel"
                    id="mobileNo"
                    name="mobileNo"
                    placeholder="Enter Mobile No"
                    value={ contactNumber || ''}
                    onChange={handleMobileChnage}
                  /> 
                   </div>
                </CCol>
                
                
              </CRow>
              <CRow className='mt-3'>
              <CCol className="justify-content-center">
                  <CButton onClick={AddNumber}>Add Mobile No.</CButton>
                  <CListGroup className='mt-3 gap-2'>
                  {
                    mobileNoInput?.map((data,index)=>(
                      <>
                      <CListGroupItem   key={index}>{data}  <CCloseButton onClick={()=>handleRemove(index)} /></CListGroupItem>
                      </>
                    ))
                  }
                  </CListGroup>
                </CCol>
              </CRow>
             
              <CRow className="justify-content-center mt-5">
                <CCol className="text-center">
                  <CButton color="primary" onClick={onSubmit}>
                    Save
                  </CButton>
                </CCol>
                <CCol className="text-center">
                  <CButton color="primary" onClick={(e) => { 
            if(e){
              e.stopPropagation();
            }
            setVisible(false);  setData({
            stationId: '_ALL',
            divisionId: '_ALL',
            zoneId: '_ALL',
            contactType: '',
            name: '',
            designation: '',
            email: '',
            remarks: '',
            mobileNo: '',
          });
          setMobileNoInput([])
          setContactNumber();
          setContentType()
          setShow(true)
          fetchData()
        }
      }>
                    Close
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CModalBody>
        </CModal>
      </div>
    </>
  )
}

export default Contact
