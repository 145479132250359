import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Rectangle, Label } from 'recharts';
import { CButton, CSpinner } from '@coreui/react';

const AppBarChart = ({ data, dataKey1, dataKey2, xAixData, name1, name2, Loding, width, division }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const startIdx = currentPage * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const currentData = data.slice(startIdx, endIdx);
  const maxValue1 = Math.max(...currentData.map(item => item[dataKey1]));
  const maxValue2 = Math.max(...currentData.map(item => item[dataKey2]));
  const maxYAxisValue = Math.max(maxValue1, maxValue2);

  return !Loding ? (
    <div>
      <ResponsiveContainer width={width || 620} height={500}>
        <BarChart
          data={currentData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAixData}>
            <Label value={division} offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis domain={[0, maxYAxisValue]}>
            <Label value="Count" angle={-90} position="insideLeft" />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="top" />
          <Bar barSize={30} name={name1} dataKey={dataKey1} fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
          <Bar barSize={30} name={name2} dataKey={dataKey2} fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
        </BarChart>
      </ResponsiveContainer>
      <div className="d-flex gap-4 justify-content-center mt-3" style={{ marginBottom: "20px" }}>
        <CButton
          color="dark"
          variant="outline"
          size="md"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
        >
          Previous
        </CButton>
        <CButton
          color="dark"
          variant="outline"
          size="md"
          onClick={handleNextPage}
          disabled={endIdx >= data.length}
        >
          Next
        </CButton>
      </div>
    </div>
  ) : (
    <div className='text-center'>
      <CSpinner />
    </div>
  );
};

AppBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      grp: PropTypes.string.isRequired,
      countR: PropTypes.number.isRequired,
      countY: PropTypes.number.isRequired,
      countG: PropTypes.number.isRequired,
    })
  ).isRequired,
  division: PropTypes.string.isRequired,
  dataKey1: PropTypes.string.isRequired,
  dataKey2: PropTypes.string.isRequired,
  xAixData: PropTypes.string.isRequired,
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  Loding: PropTypes.bool.isRequired,
  width: PropTypes.number
};

export default AppBarChart;
