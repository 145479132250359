import {
  CCard,
  CCardBody,
  CSpinner,
} from '@coreui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AppLineChart from 'src/components/AppLineChart';
import PropTypes from 'prop-types';

const DataChart = ({ device }) => {
  const [trainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`https://api.irhabd.com/v1/axle`, {
          params: { readingID: device }
        });
        setTrainData(response.data[device]);
        
      } catch (error) {
        console.log(error, 'Error fetching station data');
      } finally {
        setLoading(false);
      }
    };

    getData();
  
  }, [device]);

  return (
    <div className="wrapper d-flex flex-column  bg-light">
      <div className="body flex-grow-1 px-3">
        <CCard>
          <CCardBody>
            {loading ? (
              <div
                style={{
                  height: '500px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CSpinner />
              </div>
            ) : (
              <div style={{ marginTop: 20 }}>
                <AppLineChart
                  Loding={loading}
                  width={800}
                  colorArray={['#FC766AFF', '#5B84B1FF']}
                  dataKeyArray={['left_temp', 'right_temp']}
                  xAixData="axle_index_train"
                  xAxix="Train Index"
                  yAxis="Temperature"
                  data={trainData}
                  lable_Array={['Left temperature', 'Right temperature']}
                />
              </div>
            )}
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

DataChart.propTypes = {
  device: PropTypes.any.isRequired,
};

export default DataChart;
