/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import Select from 'react-select';
import axios from 'axios';
import { useEffect, useState } from "react";

const MultiSelect = ({ options, optionType, onChange, resetKey }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    setSelectedValues([]);
  }, [resetKey]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions || []);
  };

  return (
    <Select
      isMulti
      value={selectedValues}
      onChange={handleSelectChange}
      options={options}
      placeholder={`Select ${optionType}`}
      isClearable
    />
  );
};

const MapDropdown = ({ onFilterChange, onselectChange }) => {
  const [filterData, setFilterData] = useState({});
  const defaultSelect = "station";  // Default filter type
  const [selectedFilter, setSelectedFilter] = useState(defaultSelect);
  const [resetKey, setResetKey] = useState(defaultSelect);

  const getFilterData = async () => {
    try {
      const response = await axios.get("https://api.irhabd.com/v1/area/list-all");
      setFilterData(response.data || {});
    } catch (error) {
      console.error("Error fetching filter data", error);
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    onFilterChange({
      filterBy: selectedFilter,
      groupBy: "station",
      filterList: filterData[selectedFilter]?.map((d) => d.id || d.code) ||[],
    });
  }, [selectedFilter, filterData]);

  const handleChange = (option) => {
    onselectChange(option);
  };

  const handleFilterChange = (filter) => {
    if (filter) {
      setSelectedFilter(filter);
      setResetKey(filter);
    } else {
      setSelectedFilter(defaultSelect); 
      setResetKey(defaultSelect);
    }
  };

  return (
    <div>
      <div className="d-flex flex-row gap-3">
        <div className="me-2">
          <Select
            value={selectedFilter!==undefined ? { label: selectedFilter, value: selectedFilter } : null}
            onChange={(e) => handleFilterChange(e ? e.value : null)}
            options={filterData !==undefined ?Object.keys(filterData)?.map((f) => ({
              label: f,
              value: f
            })):[]}
            placeholder="Select filter"
            
          />
        </div>
        <div className="me-2">
          <MultiSelect
            onChange={(option) => handleChange(option)}
            optionType={selectedFilter}
            options={filterData[selectedFilter]?.map((d) => ({
              label: d.label,
              value: d.id 
            }))  || []}
            resetKey={resetKey}
          />
        </div>
      </div>
    </div>
  );
};

export default MapDropdown;
