/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { useEffect, useState, useRef, useCallback } from "react";
import MapDropdown from "../../../components/MapDropdown";
import { CCard, CCardBody, CCardHeader, CButton } from '@coreui/react';
import axios from 'axios';
import { AppFooter, AppHeader, AppSidebar } from 'src/components';

const MapMarker = ({ device, isActive, onClick, onClose }) => {
  const openInMaps = () => {
    const url = `https://www.google.com/maps?q=${device.latitude},${device.longitude}`;
    window.open(url, "_blank");
  };

  return (
    <Marker
      position={{ lat: parseFloat(device.latitude), lng: parseFloat(device.longitude) }}
      onClick={onClick}
    >
      {isActive && (
        <InfoWindow
          position={{ lat: parseFloat(device.latitude), lng: parseFloat(device.longitude) }}
          onCloseClick={onClose}
        >
          <CCard>
            <CCardHeader><strong>{device.alias ?? "-"}</strong></CCardHeader>
            <CCardBody>
              <p>Default Direction: {device.defaultDir ?? "-"}</p>
              <p>Station: {device.station ?? "-"}</p>
              <p>LC Gate: {device.lcGate ?? "-"}</p>
              <p>Rail (kms): {device.railKms ?? "-"}</p>
              <CButton color="link" onClick={openInMaps}>Open in Google Maps</CButton>
            </CCardBody>
          </CCard>
        </InfoWindow>
      )}
    </Marker>
  );
};

const DevicesMapView = () => {
  const [devices, setDevices] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [locationType, setLocationType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [activeMarkerId, setActiveMarkerId] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get(
          `https://api.irhabd.com/v1/devices/?${locationType}=${selectedData?.map(data => data?.value).join(",")}`
        );

        if (locationType === "device" && selectedData.length > 0) {
          const filteredData = selectedData?.map(selected =>
            response.data?.filter(device => device.id === selected?.value)
          );
          setDevices(filteredData.flat() || []);
        } else {
          setDevices(response.data || []);
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setError(true);
        }, 2000);
      }
    };
    fetchDevices();
  }, [selectedData, locationType]);

  const handleMarkerClick = useCallback((id) => {
    setActiveMarkerId(id);
  }, []);

  useEffect(() => {
    try {
      if (mapRef?.current && devices.length > 0 && window.google && window.google.maps) {
        const bounds = new window.google.maps.LatLngBounds();
        devices.forEach(device => {
          if (device?.latitude && device?.longitude) {
            bounds.extend({ lat: parseFloat(device.latitude), lng: parseFloat(device.longitude) });
          }
          
        });
        if (devices.length === 1) {
          mapRef.current.setZoom(18);
          mapRef.current.setCenter(bounds.getCenter());
        } else {
          mapRef.current.fitBounds(bounds);
        }
      }
    } catch (error) {
    console.log(error)
    }
   
  }, [devices]);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 8, // Set a default zoom level
  };

  useEffect(() => {
    const fetchInitialDevices = async () => {
      const response = await axios.get("https://api.irhabd.com/v1/devices");
      setDevices(response.data);
    };
    fetchInitialDevices();
  }, []);
console.log(selectedData, "seletedData")
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column  bg-light">
        <AppHeader />

        <div className="d-flex justify-content-center align-items-center mb-3">
          <MapDropdown
            onFilterChange={(d) => {
              setLocationType(d?.filterBy);
            }}
            onselectChange={(value) => {
                setSelectedData(value);
            }}
          />
        </div>
        {devices.length > 0 && (
          <div style={{ height: "600px", width: "100%" }}>
            <LoadScript
              googleMapsApiKey="AIzaSyDnrrB6Nu2FFFrM6eO-oQXFu_Y9yHp2cL4&loading=async"
              onLoad={() => console.log("Google Maps API script loaded successfully")}
              onError={(e) => {
                console.error("Error loading Google Maps API script:", e);
                alert("Failed to load Google Maps API script. Please try again later.");
              }}
              loadingElement={<div style={{ height: "100%", width: "100%" }}>Loading...</div>}
            >
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onLoad={(map) => {
                  mapRef.current = map;
                  if (devices.length === 1) {
                    map.setZoom(18);
                    map.setCenter({
                      lat: parseFloat(devices[0].latitude),
                      lng: parseFloat(devices[0].longitude),
                    });
                  } else {
                    const bounds = new window.google.maps.LatLngBounds();
                    devices.forEach(device => {
                      if (device?.latitude && device?.longitude) {
                        bounds.extend({ lat: parseFloat(device.latitude), lng: parseFloat(device.longitude) });
                      }
                    });
                    map.fitBounds(bounds);
                  }
                }}
              >
                {devices.map((device) => {
                  const { latitude, longitude, id } = device;
                  if (!latitude || !longitude) return null;

                  const isActive = activeMarkerId === id;

                  return (
                    <MapMarker
                      key={id}
                      device={device}
                      isActive={isActive}
                      onClick={() => handleMarkerClick(id)}
                      onClose={() => setActiveMarkerId(null)}
                    />
                  );
                })}
              </GoogleMap>
            </LoadScript>
          </div>
        )}
      </div>
      <AppFooter />
    </div>
  );
};

export default DevicesMapView;
