import React, { useEffect, useState } from 'react'
import { AppSidebar, AppFooter, AppHeader } from '../../../components/index'
import { dateToStr } from 'src/components/DRTable'

import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import axios from 'axios'

const Device2 = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deviceListById, setDeviceListById] = useState([])

  const getData = async () => {
    setIsLoading(true)
    const response = await axios.get('https://api.irhabd.com/v1/readings-device')
    const readingsDevice = response.data
    const deviceList = readingsDevice.map((d) => d.device_id)
    setDeviceListById(deviceList)
    const statusData = await axios.get('https://api.irhabd.com/v1/device-status', {
      params: {
        devices: deviceList,
      },
    })
    const readingDeviceStatus = statusData.data.resp
    const readingsDeviceWithStatus = readingsDevice.map((d) => ({
      ...d,
      ...readingDeviceStatus[d.device_id],
    }))
    setData(readingsDeviceWithStatus)
    setData(readingsDeviceWithStatus)
    setIsLoading(false)
  }

  useEffect(() => {
    getData()

    let interval = setInterval(() => {
      getData()
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const columns = [
    { accessor: 'device_id', Header: 'Device Id', width: 110 },
    {
      accessor: 'timestamp',
      Header: 'Time',
      Cell: ({ value }) => {
        if (value) {
          let tmpDate = new Date(value.toString())
          return dateToStr(tmpDate)
        }
        return ''
      },
      width: 160,
    },
    { accessor: 'train_dir', Header: 'Train Dir', width: 60 },
    {
      accessor: 'rake_subtype',
      Header: 'Rake Type',
      width: 160,
      Cell: ({ value }) => {
        return value === 'UNKNOWN' ? 'WAGON' : value
      },
      width: 120,
    },
    { accessor: 'coach_count', Header: 'Coach Count', width: 60 },
    { accessor: 'axle_count', Header: 'Axle Count', width: 60 },
    { accessor: 'max_right_temp', Header: 'Max Right Temp', width: 60 },
    { accessor: 'max_left_temp', Header: 'Max Left Temp', width: 60 },
    { accessor: 'max_diff_temp', Header: 'Max Diff Temp', width: 60 },
    {
      accessor: 'avg_speed',
      Header: 'Avg Speed',
      Cell: ({ value }) => {
        const tmpSpeed = Math.floor(parseFloat(value) * 3.6).toFixed(2)
        return tmpSpeed > 165 ? ' - ' : tmpSpeed
      },
      width: 60,
    },
    {
      accessor: 'server_timestamp',
      Header: 'Update Time',
      Cell: ({ value }) => {
        if (value) {
          let tmpDate = new Date(value.toString())
          return dateToStr(tmpDate)
        }
        return ''
      },
      width: 140,
    },
    {
      accessor: 'mains_status',
      Header: 'Mains Status',
      width: 80,
    },
    {
      accessor: 'com_status',
      Header: 'Network Status',
      width: 80,
    },
    {
      accessor: 'com_status',
      Header: 'CPU Status',
      width: 80,
    },
    { accessor: 'min_right_temp', Header: 'Min Right Temp', width: 60 },
    { accessor: 'min_left_temp', Header: 'Min Left Temp', width: 60 },
  ]

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-white">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <ReactTable
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  background:
                    rowInfo && data[rowInfo.index].hot_axle && 'rgba(229, 83, 83, 0.7)',
                },
              }
            }}
            loading={isLoading}
            className="-striped -highlight"
            columns={columns}
            data={data}
          />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default Device2
