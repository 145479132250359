
import { CNavItem } from '@coreui/react'

const _nav = [
  // {
  //   component: CNavItem,
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Axle',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Division',
  //   to: '/division',
  // },
  {
    component: CNavItem,
    name: 'Temperature Setting',
    to: '/temp-setting',
  },
  // {
  //   component: CNavItem,
  //   name: 'Station',
  //   to: '/station',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Zone',
  //   to: '/zone',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Device',
  //   to: '/device',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Device Status',
  //   to: '/device_status',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Alert History',
  //   to: '/alert-history',
  // },
  //
  // {
  //   component: CNavItem,
  //   name: 'Alert Config',
  //   to: '/alert-conf',
  // },
  // {
  //   component: CNavItem,
  //   name: 'HABD History',
  //   to: '/habd-history',
  // },
  {
    component: CNavItem,
    name: 'Device Reading',
    to: '/device-reading',
  },
  {
    component: CNavItem,
    name: 'SMS Alert',
    to: '/sms-alert',
  },
  {
    component: CNavItem,
    name: 'Reading Overview',
    to: '/reading-overview',
  },
  {
    component: CNavItem,
    name: 'Statistics report',
    to: '/statistics-report',
  },
  {
    component: CNavItem,
    name: 'Map view',
    to: '/map-view',
  },
  // {
  //   component: CNavItem,
  //   name: 'Search Table2',
  //   to: '/table2',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Pages',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Users',
  //       to: '/users',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
]

export default _nav
