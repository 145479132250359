/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { CChart } from '@coreui/react-chartjs';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const AppPieChart = ({lables,data, backgroundColor}) =>{ 
    // eslint-disable-next-line react/prop-types

const doughnutData = {
  labels: lables,
  datasets: [
    {
      backgroundColor: backgroundColor,
      // eslint-disable-next-line react/prop-types
      data:  data,
    },
  ],
};
  return(
  
  <CChart
    type="pie"
    data={doughnutData}
   
    options={{
    
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: getComputedStyle(document.documentElement).getPropertyValue('--cui-body-color'),
          },
        },
      },
    }}
  />
);}

export default AppPieChart;
AppPieChart.prototype ={
  labels :PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  backgroundColor :PropTypes.arrayOf(PropTypes.string).isRequired,
}
