/* eslint-disable array-callback-return */
import { CCard, CCardBody } from '@coreui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';

const DataChart = ({ device }) => {
  const [trainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`https://api.irhabd.com/v1/axle`, {
          params: { readingID: device, addCoach: true }
        });
        setTrainData(response.data[device]);
      } catch (error) {
        console.log(error, 'Error fetching station data');
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [device]);

  const heading = [{
    Header: 'View Table',
    columns: [
      { accessor: 'axle_index_train', Header: 'Axle Train Index', width:70,  },
      { accessor: 'axle_index_coach', Header: 'Axle Coach Index', width:70, },
      { accessor: 'coach_subtype', Header: 'Coach Subtype' },
      { accessor: 'right_temp', Header: 'Right Temp' },
      { accessor: 'left_temp', Header: 'Left Temp' },
      { accessor: 'diff_temp', Header: 'Diff Temp.' },
      { accessor: 'speed', Header: 'Speed' },
     
    ],
  }];

  const viewData = [];
  Object.keys(trainData).forEach((data) => {
    const coachSubtype = trainData[data].coach_subtype;
    const viewDatawithSubtype = trainData[data].axles.map((axle) => ({
      ...axle,
      coach_subtype: axle.axle_index_coach === 1 ? coachSubtype : ''
    }));
    viewData.push(...viewDatawithSubtype);
  });

  return (
    <div className="wrapper d-flex flex-column  bg-light">
      <div className="body flex-grow-1 px-3">
        <CCard>
          <CCardBody>
            <div style={{ marginTop: 20 }}>
              <ReactTable
                columns={heading}
                data={viewData}
                loading={loading}
                showPagination={false} 
                pageSize={viewData.length} 
                getTrProps={(state, rowInfo) => {
                  if (!rowInfo) return {};
                  const hotAxleType = rowInfo.original?.hot_axle_type;
                  const selectedColors = JSON.parse(localStorage.getItem('selectedColors')) || {};
                  return {
                    style: {
                      backgroundColor: hotAxleType === 'red'
                        ? selectedColors.red
                        : hotAxleType === 'yellow'
                        ? selectedColors.yellow
                        : hotAxleType === 'green'
                        ? selectedColors.green
                        : hotAxleType === 'gray'
                        ? selectedColors.gray
                        : undefined,
                    },
                  };
                }}
              />
            </div>
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

DataChart.propTypes = {
  device: PropTypes.any.isRequired,
};

export default DataChart;
