/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

const MultiSelect = ({ options, optionType, onChange, resetKey }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [resetKey]);

  const handleSelectionChange = (selected) => {
    setSelectedOptions(selected || []);
    onChange(selected || []);  
  };

  return (
    <Select
      isMulti
      value={selectedOptions}
      onChange={handleSelectionChange}
      options={options}
      placeholder={`Select ${optionType}`}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  );
};

const FilterSelect = ({ filterOptions, selectedFilter, onChange }) => {
  const handleChange = (selected) => {
    onChange(selected?.value || null);  
  };

  return (
    <Select
      value={filterOptions.find((option) => option.value === selectedFilter)}
      onChange={handleChange}
      options={filterOptions}
      placeholder="Select filter"
      className="basic-single-select"
      classNamePrefix="select"
    />
  );
};


const FilterDropDown = ({ onFilterChange, onselectChange }) => {
  const [filterData, setFilterData] = useState({});
  const defaultSelect = "station";
  const [selectedFilter, setSelectedFilter] = useState(defaultSelect);
  const [resetKey, setResetKey] = useState(defaultSelect);

  const getFilterData = async () => {
    try {
      const response = await axios.get("https://api.irhabd.com/v1/area/list-all");
      const { device, ...rest } = response.data;
      setFilterData(rest);
    } catch (error) {
      console.error("Error fetching filter data", error);
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    onFilterChange({
      filterBy: selectedFilter,
      groupBy: "station",
      filterList: filterData[selectedFilter]?.map((d) => d.id || d.code),
    });
  }, [selectedFilter, filterData]);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    setResetKey(filter);
  };

  const handleMultiSelectChange = (option) => {
    onselectChange(option);  
  };

  const filterOptions = Object.keys(filterData).map((key) => ({
    label: key,
    value: key,
  }));

  return (
    <div>
      <div className="d-flex flex-row gap-3 mb-3">
        <div className="me-2">
          <FilterSelect
            filterOptions={filterOptions}
            selectedFilter={selectedFilter}
            onChange={handleFilterChange}
          />
        </div>
        <div className="me-2">
          <MultiSelect
            onChange={handleMultiSelectChange}
            optionType={selectedFilter}
            options={filterData[selectedFilter]?.map((d) => ({
              label: d.label,
              value: d.id,
            }))}
            resetKey={resetKey}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterDropDown;
