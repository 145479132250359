import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from 'recharts';
import { CSpinner } from '@coreui/react';

const AppLineChart = ({
  data,
  xAixData,
  Loding,
  width = '100%',
  height = 400,
  dataKeyArray,
  colorArray,
  yAxis,
  lable_Array,
  xAxix,
}) => {

  const maxYAxisValue = Math.max(
    ...dataKeyArray.map((key) => Math.max(...data.map((item) => item[key])))
  );
  const minYAxisValue = Math.min(
    ...dataKeyArray.map((key) => Math.min(...data.map((item) => item[key])))
  )-4;
 
  return !Loding ? (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
      <ResponsiveContainer width={width} height={height} >
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAixData}>
            <Label value={xAxix} offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis domain={[minYAxisValue, maxYAxisValue]}>
            <Label value={yAxis} angle={-90} position="insideLeft" />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="top" />
          {dataKeyArray.map((val, index) => (
            <Line
              key={lable_Array[index]}
              type="monotone"
              dataKey={val}
              stroke={colorArray[index]}
              name={lable_Array[index]}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
      <CSpinner />
    </div>
  );
};

AppLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAixData: PropTypes.string.isRequired,
  Loding: PropTypes.bool.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  dataKeyArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  colorArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  yAxis: PropTypes.string.isRequired,
  lable_Array: PropTypes.arrayOf(PropTypes.string),
  xAxix: PropTypes.string.isRequired,
};

export default AppLineChart;
